<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    @beforeOpen="(event) => processParams(event.ref.params._rawValue)"
  >
    <!-- Begin::Header -->
    <div class="modal__header">
      <span class="modal__title"> {{ stock.id ? "Edit" : "New" }} stock </span>
    </div>
    <!-- End::Header -->
    <!-- Begin::Body -->
    <div class="modal__content">
      <!-- Begin::Details section -->
      <h5 class="fw-bolder mb-6">Stock Quantity</h5>
      <form @submit.prevent="saveStock" ref="form">
        <div class="d-flex flex-row row mb-12">
          <!-- Begin::Left col -->
          <div class="d-flex flex-column col-12 mb-5">
            <label for="quantity">Quantity</label>
            <input
              id="quantity"
              type="number"
              min="0"
              step="1"
              class="form-control"
              v-model="stock.quantity"
              required
            />
          </div>
          <!-- End::Right col -->
        </div>
      </form>
    </div>
    <!-- End::Header -->
    <!-- Begin::Footer -->
    <div class="modal__action">
      <!-- <a class="btn btn-lg btn-primary me-2" @click="$emit('confirm', operation)"
        >Confirm</a
      > -->
      <a class="btn btn-lg btn-primary me-2" @click.prevent="onClickConfirm"
        >Confirm</a
      >
      <a class="btn btn-lg btn-secondary" @click="$emit('cancel')">Cancel</a>
    </div>
    <!-- End::Header -->
    <button class="modal__close btn btn-icon btn-link" @click="close">
      <i class="bi bi-x-lg"></i>
    </button>
  </vue-final-modal>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import _ from "lodash";

import ApiService from "@/core/services/ApiService";

export default {
  emits: ["confirm", "cancel"],
  data() {
    return {
      stock: {
        product: "",
        quantity: 0,
      },
    };
  },
  methods: {
    onClickConfirm() {
      this.$refs.form.requestSubmit();
    },
    saveStock() {
      console.log(JSON.parse(JSON.stringify(this.stock)));

      if (this.stock.id) {
        const config = {
          responseToast: {
            text: "Stock updated successfully.",
          },
        };
        ApiService.update("/stocks", this.stock.id, this.stock)
          .then(({ data }) => {
            console.log(data);
            this.$emit("confirm", data);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        const config = {
          responseToast: {
            text: "Stock created successfully.",
          },
        };
        ApiService.post("/stocks", this.stock)
          .then(({ data }) => {
            console.log(data);
            this.$emit("confirm", data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    processParams(stock) {
      if (stock) {
        const stockTmp = _.cloneDeep(stock);
        if (stockTmp.product && stockTmp.product.id) {
          stockTmp.product = stockTmp.product.id;
        }

        this.stock = stockTmp;
      }
    },
  },
};
</script>